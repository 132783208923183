import React, { useState, useEffect, useRef } from "react";
import { ReactSearchAutocomplete } from 'react-search-autocomplete'
import { useLocation } from "react-router-dom";
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import { savePDF } from "@progress/kendo-react-pdf";
import { drawDOM, exportPDF } from '@progress/kendo-drawing';
import { handleQuery } from "../../helpers/queryHandler";
import CertificateMockup from "../CertificateMockup/CertificateMockup";
import Courses from '../../assets/json/courses.example.json';
import clientAxios from '../../config/clientAxios';

import Axios from "axios";
import { notify } from "react-notify-toast";

export default function CertificateCreator() {

  const date = new Date().toISOString().substr(0, 7);
  
  const queryData = useLocation().search;
  
  let certificate = useRef<any>(null);
  
  const [responseFetch, setResponse] = useState<any>("")
  
  const [state, setState] = useState({
    studentName: "",
    titleName: Courses[0].name,
    courseDateFrom: date,
    courseDateTo: date,
    email: "",
    qualitySealCert: false
  });
  const [validated, setValidated] = useState(false);
  
  const [disableLinkButton, setDisableLinkButton] = useState(false);
  
  const [certExt, setCertExt] = useState(true)
  
  const [classNameEx, setClassNameEx] = useState("fullscreen-image")
  
  const [items, setItems] = useState([])

  const handleOnSearch = (string: any) => {
    // onSearch will have as the first callback parameter
    // the string searched and for the second the results.
    if (string.length >= 3) {
      let student = string;
      clientAxios.get(`/students/${student}`)
        .then(function (response) {
          setItems(response.data);
        });
    }

  }
  
  const handleOnHover = (items: any) => {
    // the item hovered
   
  }

  const handleOnSelect = (items: any) => {
    // the item hovered
    setState({
      studentName: `${items.name} ${items.surname}`,
      titleName: state.titleName,
      courseDateFrom: state.courseDateFrom,
      courseDateTo: state.courseDateTo,
      email: items.email,
      qualitySealCert: state.qualitySealCert,
    });
  }

  const formatResult = (items: any) => {
    return (
      <>
      <span style={{ display: 'block', textAlign: 'left', fontSize: '11px', fontWeight: "bold"}}>Nombre: {items.name} {items.surname}</span>
      <span style={{ display: 'block', textAlign: 'left', fontSize: '9px' }}>Comision: {items.classroom}</span>
      <span style={{ display: 'block', textAlign: 'left', fontSize: '9px' }}>Email: {items.email}</span>
      </>
    )
  }

  const handleChangeBackground = () => {
    setCertExt(!certExt)
    if (!certExt) {
      setState({
        ...state,
        qualitySealCert: false,
      });
      setClassNameEx("fullscreen-image")
    } else {
      setState({
        ...state,
        qualitySealCert: true,
      });
      setClassNameEx("fullscreen-imageEx")
    }
  }

  const handleChange = (e: { target: { name: string; value: string } }) => {
    let { name, value } = e.target;
    setState({
      ...state,
      [name]: value,
    });
  };

  /**
   * Download PDF function with set properties
   *
   * @param {object} object Object containing studentName and courseDate params, if not present it'll be taken from component's state
   */
  const downloadPDF: ({
    studentName,
    courseDateFrom,
    courseDateTo,
    email,
  }: {
    studentName: string;
    courseDateFrom: string;
    courseDateTo: string;
    email: string;
  }) => void = ({ studentName, courseDateFrom, courseDateTo, email }) => {
    const options = {
      paperSize: "A4",
      landscape: true,
      margin: 0,
      fileName: `Certificate of Completion - ${studentName} - ${courseDateTo}`,
    };

    savePDF(certificate.current, options);
  };

  const savePng: ({ email }: { email: string }) => void = ({ email }) => {
    drawDOM(certificate.current, { paperSize: "A4", landscape: true }).then((group) => {
      return exportPDF(group);
    }).then((dataUri) => {
      clientAxios.post("exportToPng/getPng/" + email , { img: dataUri.split(';base64,')[1] , qualitySealCert : state.qualitySealCert }).then((responseFetch) => {
        notify.show(`${responseFetch.data.message}`, responseFetch.data.success ? "success" : "error");
        setDisableLinkButton(false);
        if (responseFetch.data && responseFetch.data.url) {
          setResponse(responseFetch.data.url);
          setState({
            studentName: "",
            titleName: Courses[0].name,
            courseDateFrom: date,
            courseDateTo: date,
            email: "",
            qualitySealCert: state.qualitySealCert,
          });
          window.scrollTo(0, 0);
        }
      })
    });
  }

  useEffect(() => {
    const queryParam = handleQuery(queryData);
    if (queryParam) {
      setState({ ...state, ...queryParam.state });

      if (queryParam.download) {
        downloadPDF(queryParam.state);
      }
    }
    //eslint-disable-next-line
  }, []);

  const copyText = () => {
    navigator.clipboard.writeText(responseFetch);
    notify.show(`Copiado al portapapeles!`, "success");
  };

  const handleSubmit = (event: any, saveType: string) => {
    event.preventDefault();
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.stopPropagation();
    } else {
      if (saveType === "pdf") {
        downloadPDF(state)
      } else if (saveType === "png") {
        setDisableLinkButton(true);
        savePng(state)
      }
    }

    setValidated(true);
  };

  let saveTypeLet = "";

  return (
    <Container >
      {responseFetch !== "" &&
        <div className="bg-light shadow rounded p-3 mb-3">
          <Row className="overflow-auto">
            <p className="mb-3" style={{ fontSize: "1rem" }}>Por este enlace podes acceder a tu certificado:<br />{responseFetch}</p>
          </Row>
          <Row>
            <Button onClick={copyText}>Copiar al portapales el enlace!</Button>
          </Row>
        </div>
      }
      <Row>
        <Col md={3} className="bg-light shadow rounded p-3">
          <p className="lead">Datos a completar</p>
          <div className="d-flex align-items-baseline justify-content-between">
            <p className="d-inline">Cambiar Certificado</p>
            <div className="pe-3">
              <label className="switch">
                <input onClick={handleChangeBackground} type="checkbox"/>
                <span className="slider round"></span>
              </label>
            </div>
          </div>
          <hr />
          <Form className="createCertGroup" noValidate validated={validated} onSubmit={(event) => handleSubmit(event, saveTypeLet)}>
            <Form.Group>
              <Form.Label>Nombre del curso</Form.Label>
                <Form.Control
                  as="select"
                  onChange={handleChange}
                  name="titleName"
                  defaultValue="Choose..."
                  required>
                  {Courses.map(course => (
                    <option value={course.name}>{course.name}</option>
                   ))
                  }
                </Form.Control>
            </Form.Group>
            <Form.Label>Nombre del alumno o email</Form.Label>
            <ReactSearchAutocomplete
            items={items}
            onSearch={handleOnSearch}
            onHover={handleOnHover}
            onSelect={handleOnSelect}
            autoFocus
            fuseOptions={{ keys: ["name", "surname", "email"] }}
            formatResult={formatResult}
            />

<Form.Group>
              <Form.Label>Email</Form.Label>
              <Form.Control
                type="text"
                onChange={handleChange}
                value={state.email}
                name="email"
                readOnly
                required
              />
            </Form.Group>

            <Form.Group>
              <Form.Label>Desde</Form.Label>
              <Form.Control
                type="month"
                onChange={handleChange}
                value={state.courseDateFrom}
                name="courseDateFrom"
              />
            </Form.Group>

            <Form.Group>
              <Form.Label>Hasta</Form.Label>
              <Form.Control
                type="month"
                onChange={handleChange}
                value={state.courseDateTo}
                name="courseDateTo"
              />
            </Form.Group>
            <Button type="submit" disabled={disableLinkButton} onClick={() => saveTypeLet = "png"} className="my-3 mx-2 mx-lg-0 widthButton">
              Generar Link
            </Button>
            <Button type="submit" className="my-3" onClick={() => saveTypeLet = "pdf"}>
              Descargar Certificado
            </Button>
          </Form>
        </Col>

        <Col md={9} className="pl-0 pr-0">
          <CertificateMockup
            background={classNameEx}
            ref={certificate}
            student={state.studentName}
            course={state.titleName}
            dateFrom={Intl.DateTimeFormat("en-US", {
              month: "long",
              year: "numeric",
            }).format(new Date(state.courseDateFrom).setUTCHours(20))}
            dateTo={Intl.DateTimeFormat("en-US", {
              month: "long",
              year: "numeric",
            }).format(new Date(state.courseDateTo).setUTCHours(20))}
          />
        </Col>
      </Row>
    </Container>
  );
}
