import React, { useContext } from "react";
import "./NavigationBar.css";
import logoImg from "../../assets/img/rollingcode-logo.png";
import { Navbar, Nav, NavDropdown } from "react-bootstrap";
import { Link } from "react-router-dom";
import { UserAuth } from "../../context/auth";

export default function NavigationBar() {
  const { user } = useContext(UserAuth);

  return (
    <Navbar bg="dark" variant="dark" expand="lg">
      <Link to="/">
        <Navbar.Brand>
          <img height="50" src={logoImg} alt="RollingCode" />
        </Navbar.Brand>
      </Link>
      {user._id && (
        <Link
          className="text-white"
          to="/administrate/certificate-list"
        >
          <p>Ver certificados</p>
        </Link>
      )}
      <Navbar.Toggle aria-controls="basic-navbar-nav" />
      <Navbar.Collapse id="basic-navbar-nav">
        <Nav className="mr-auto">
          {user._id ? (
            <NavDropdown title="Generar certificado" id="basic-nav-dropdown">
              <Link
                className="text-dark text-decoration-none"
                to="/generate/single"
              >
                <p>Individual</p>
              </Link>
            </NavDropdown>
          ) : null}
        </Nav>
        <Nav className="ml-auto">
          <NavDropdown
            title={user.name ? `User: ${user.name}` : `Sin iniciar sesión`}
            id="xd"
          >
            {user._id ? (
              <Link
                className="text-dark text-decoration-none"
                to="/auth/logout"
              >
                <p>Cerrar sesión</p>
              </Link>
            ) : (
              <Link className="text-dark text-decoration-none" to="/auth/login">
                <p>Iniciar sesión</p>
              </Link>
            )}
          </NavDropdown>
        </Nav>
      </Navbar.Collapse>

    </Navbar>
  );
}
