import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import { BrowserRouter as Router, Route } from "react-router-dom";
import "./styles/main.css";
import Notifications from "react-notify-toast";
import UserAuthProvider from "./context/auth";
import { QueryParamProvider } from 'use-query-params';

ReactDOM.render(
  <React.StrictMode>
    <Notifications />
    <Router>
      <QueryParamProvider ReactRouterRoute={Route}>
        <UserAuthProvider>
          <App />
        </UserAuthProvider>
      </QueryParamProvider>
    </Router>
  </React.StrictMode>,
  document.getElementById("root")
);
