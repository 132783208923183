import React from "react";
import { Container, Row, Col } from "react-bootstrap";

const CertificateMockup = React.forwardRef(
  (
    props: {
      background: string;
      student: string;
      course: string;
      dateFrom: string;
      dateTo: string;
    },
    ref: any
  ) => (
    <Container ref={ref} className="w-100 h-100 pl-0 pr-0">
      <div className={props.background}>
        <Row className="top-row" />
        <Row className="text-center">
          <Col>
           <p className="name">{props.student}</p>
            <p className="subtitle">
              successfully completed{" "}
              <span className="titlebold">{props.course}</span>{" "}
              <p className="date">
                from {props.dateFrom} to {props.dateTo}
              </p>
            </p>
          </Col>
        </Row>
      </div>
    </Container>
  )
);

export default CertificateMockup;
