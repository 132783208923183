import React, { useContext } from "react";
import { Redirect, Route } from "react-router-dom";
import { UserAuth } from "../../../context/auth";

export default function PrivateRoute({ children, ...rest }: any) {
  const { user } = useContext(UserAuth);

  return (
    <Route {...rest}>
      {user._id === "" ? <Redirect to="/auth/login" /> : children}
    </Route>
  );
}
