import React, { useContext, useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { UserAuth } from "../../../context/auth";

export default function Logout() {
  const { logout } = useContext(UserAuth);
  const history = useHistory();

  useEffect(() => {
    logout();
    history.push("/");
  });

  return (
    <Row>
      <Col />
      <Col className="text-center">
        <h1 className="display-4">Sad to see you go</h1>
        <p className="lead">Come back soon!</p>
      </Col>
      <Col />
    </Row>
  );
}
