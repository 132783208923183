import { faDoorOpen } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect } from "react";
import { useContext } from "react";
import { useState } from "react";
import { Row, Col, Button, Form } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { UserAuth } from "../../../context/auth";
import { useQueryParams, StringParam } from 'use-query-params';

export default function Login() {

  const [query,] = useQueryParams({
    titleName: StringParam,
    studentName: StringParam,
    courseDateFrom: StringParam,
    courseDateTo: StringParam,
    email: StringParam
  });
  const { titleName, studentName, courseDateFrom, courseDateTo, email } = query;

  const JWT_TOKEN = localStorage.getItem("token");
  let userdata = JSON.parse(`${localStorage.getItem("userdata")}`);

  const history = useHistory();
  const { login, user } = useContext(UserAuth);
  const [state, setState] = useState({
    email: "",
    password: "",
  });
  const [isLoading, setIsLoading] = useState(false);

  const handleChange = (e: { target: { name: any; value: any } }) => {
    let { name, value } = e.target;
    setState({ ...state, [name]: value });
  };

  const handleSubmit = (e: { preventDefault: () => void }) => {
    e.preventDefault();
    setIsLoading(true);
    setTimeout(() => setIsLoading(false), 2000);
    login(state);
  };

  useEffect(() => {
    if (JWT_TOKEN && userdata) {
      if (JWT_TOKEN !== "" && userdata !== {}) {
        login(state)
      }
    }
    //eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (user._id !== "") {
      if (titleName || studentName || courseDateFrom || courseDateTo || email) {
        history.push(`/generate/single?titleName=${titleName || ""}&studentName=${studentName || ""}&courseDateFrom=${courseDateFrom || new Date().toISOString().substr(0, 7)}&courseDateTo=${courseDateTo || new Date().toISOString().substr(0, 7)}&email=${email || ""}`)
      } else {
        history.push("/generate/single");
      }
    }

    //eslint-disable-next-line
  }, [user]);

  return (
    <Row>
      <Col xs={0} lg={4}>
      </Col>
      <Col xs={12} lg={4} className="bg-white shadow rounded p-5">
        <Form onSubmit={handleSubmit}>
          <Form.Group>
            <Form.Label>E-mail address:</Form.Label>
            <Form.Control
              type="email"
              name="email"
              value={state.email}
              onChange={handleChange}
              required
            />
          </Form.Group>

          <Form.Group>
            <Form.Label>Password:</Form.Label>
            <Form.Control
              type="password"
              name="password"
              value={state.password}
              onChange={handleChange}
              required
            />
          </Form.Group>
          <Button disabled={isLoading} variant="success" type="submit">
            <FontAwesomeIcon icon={faDoorOpen} /> Log in
          </Button>
        </Form>
      </Col>
      <Col xs={0} lg={4}>
      </Col>
    </Row>
  );
}
