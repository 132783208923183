import React, { useState, useEffect } from "react";
import Axios from "axios";
import ListGroup from 'react-bootstrap/ListGroup'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimesCircle } from "@fortawesome/free-solid-svg-icons";
export default function CertificateList() {
  interface Provider {
    email: string;
    createdAt: string;
    filename: string;
  }
  const certificateFilesUrl = 'https://certs.rollingcodeschool.com';
  const [responseFetch, setResponse] = useState<Provider[]>([]);
  useEffect(() => {
    getCertificates();
  }, []);

  function getCertificates() {
    Axios.get("/api/certificate").then((responseFetch) => {
      setResponse(responseFetch.data);
    })
  }

  function deleteCert(filename: string, email: string) {
    let dialogResponse = window.confirm(`Estas segur@ que deseas eliminar el certificado de: ${email}?`);
    if (dialogResponse === true) {
      Axios.delete("/api/certificate/" + filename
      ).then((responseFetch) => {
        getCertificates();
      })
    } else {
      console.log("no");
    }
  }

  return <div>
    <h5 className="text-center">{responseFetch.length === 0 ? "cargando " : responseFetch.length} certificados generados</h5>
    <ListGroup>
      {responseFetch.map((certificate) => (
        <ListGroup.Item className="certificate-list"><a target="_blank" rel="noreferrer" href={`${certificateFilesUrl}/${certificate.filename}_1.png`} >
          {certificate.email}</a> -
          <small> fecha de creacion: {new Date(certificate.createdAt).toLocaleDateString('en-US', {
            day: 'numeric',
            month: 'short',
            year: 'numeric',
          })} </small>
          <span onClick={() => deleteCert(certificate.filename, certificate.email)}>
            <FontAwesomeIcon icon={faTimesCircle} color="red" pull="right" />
          </span>
        </ListGroup.Item>
      ))}
    </ListGroup>
  </div >;
}
