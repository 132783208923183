import React, { useContext } from "react";
import { Button, Col, Row } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import logoImg from "../../assets/img/rollingcode-logo.png";
import { faDoorOpen } from "@fortawesome/free-solid-svg-icons";
import { Link, Redirect } from "react-router-dom";
import { UserAuth } from "../../context/auth";

export default function Home() {
  const { user } = useContext(UserAuth);

  if (user._id === "") {
    return (
      <Row className="text-center d-flex justify-content-center align-items-center h-100-minus">
        <Col>
          <h1>RollingCode Academy</h1>
          <p className="lead">Certificate Generator</p>
        </Col>
        <Col>
          <p className="lead">
            You need to be logged-in in order to use this page
          </p>
          <Link to="/auth/login">
            <Button>
              <FontAwesomeIcon icon={faDoorOpen} /> Log in
            </Button>
          </Link>
        </Col>
      </Row>
    );
  } else {
    return <Redirect to="/generate/single" />;
  }
}
