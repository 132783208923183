import Axios from "axios";
import React, { useState, createContext } from "react";
import { notify } from "react-notify-toast";
import authToken from "../config/authToken";

export const UserAuth = createContext({
  user: { _id: "", name: "" },
  login: (state: { email: string; password: string }) => { },
  logout: () => { },
});

export default function UserAuthProvider({ children }: { children: any }) {
  const JWT_TOKEN = localStorage.getItem("token");
  let userdata = JSON.parse(`${localStorage.getItem("userdata")}`);

  const [user, setUser] = useState<userType>({
    _id: "",
    name: "",
  });

  const login = (state: { email: string; password: string }) => {
      if ((JWT_TOKEN && userdata) && (JWT_TOKEN !== "" && userdata !== {})  && 
        (userdata !== null) ) { 
          authToken(JWT_TOKEN);
          setUser({ _id: userdata.id, name: userdata.username });
          notify.show(`Welcome ${userdata.username}!`, "success"); 
       }
       else {
          Axios.post("/api/user/login", state).then((response) => {
            const token = response.headers.authorization
            authToken(token);
            localStorage.setItem("token", token);
            setUser({ _id: response.data.id, name: response.data.name });
            notify.show(`Welcome ${response.data.name}!`, "success");
          });
        }
  };

  const logout = () => {
    localStorage.removeItem("token");
    setUser({ _id: "", name: "" });
    notify.show(`Goodbye!`, "success");
  };

  return (
    <UserAuth.Provider
      value={{
        user,
        login: (state: { email: string; password: string }) => login(state),
        logout,
      }}
    >
      {children}
    </UserAuth.Provider>
  );
}

interface userType {
  _id: string;
  name: string;
}
