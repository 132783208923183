import React from "react";
import CertificateCreator from "./components/CertificateCreator/CertificateCreator";
import NavigationBar from "./components/NavigationBar/NavigationBar";
import { Container } from "react-bootstrap";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import CertificateList from "./components/CertificateList/CertificateList";
import Home from "./components/Home/Home";
import Login from "./components/Auth/Login/Login";
import Logout from "./components/Auth/Logout/Logout";
import PrivateRoute from "./components/Auth/PrivateRoute/PrivateRoute";

function App() {
  return (
    <>
      <Router>
        <NavigationBar />
        <Switch>
          <Container fluid className="my-5">
            <Route path="/" exact>
              <Home />
            </Route>

            <PrivateRoute exact path="/generate/single">
              <CertificateCreator />
            </PrivateRoute>

            <PrivateRoute path="/administrate/certificate-list">
              <CertificateList />
            </PrivateRoute>

            <Route path="/auth/login" component={Login} />
            <Route path="/auth/logout" component={Logout} />
          </Container>
        </Switch>
      </Router>
    </>
  );
}

export default App;
