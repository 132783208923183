/**
 * Grabs the query params and returns a usable
 * object
 *
 * @param {*} query useLocation.search() hook
 * @returns {object} Object ready for state spread and whether has to download or now
 */
export const handleQuery = (query: any) => {
  if (!query) {
    return false;
  }

  query = query.split("&");

  // Deletes the first question mark from query
  query[0] = query[0].replace("?", "");

  let objectFromQuery: any = {};
  let download = false;

  // Splits the entry of query in two parts, suitable
  // for putting data into an object
  for (let entry of query) {
    let splittedEntry = entry.split("=");

    if (splittedEntry[0] === "download") {
      download = true;
    }

    // If an space is detected (in form of %20), replace it with a
    // proper space character
    if (splittedEntry[1].includes("%20")) {
      splittedEntry[1] = splittedEntry[1].replaceAll("%20", " ");
    }

    objectFromQuery[splittedEntry[0]] = splittedEntry[1];
  }

  return { state: objectFromQuery, download };
};
